import Link from 'next/link'
import { CSSProperties } from 'react'

import { Button, Typography } from 'antd'

import IonIcon from '../systems/ion-icon'

import { ROUTES } from '@/constants/routes'

function ProfileButton({
  block = false,
  style,
}: {
  block?: boolean
  style?: CSSProperties
}) {
  return (
    <Link href={ROUTES.PROFILE.INDEX}>
      <Button
        type="text"
        style={{
          padding: 0,
          display: 'flex',
          alignItems: 'center',
          background: 'none',
          ...style,
        }}
        icon={<IonIcon name="person-outline" style={{ fontSize: 24 }} />}
        block={block}
      >
        <Typography.Text style={{ fontSize: 16 }}>My Profile</Typography.Text>
      </Button>
    </Link>
  )
}

export default ProfileButton

import { forwardRef } from 'react'
import Link from 'next/link'

import { Avatar, AvatarProps } from 'antd'

import useAvatar from '@/hooks/useAvatar'

import { ROUTES } from '@/constants/routes'

import './index.scss'

export type Ref = HTMLElement

const UserAvatar = forwardRef<Ref, AvatarProps>(
  ({ size = 50, className = '', ...rest }, ref) => {
    const { avatar, onError } = useAvatar()

    return (
      <Link href={{ pathname: ROUTES.PROFILE.INDEX }}>
        <Avatar
          alt="User Avatar"
          ref={ref}
          className={`user-avatar ${className}`}
          src={avatar}
          size={size}
          onError={onError}
          {...rest}
        />
      </Link>
    )
  },
)

UserAvatar.displayName = 'UserAvatar'

export default UserAvatar

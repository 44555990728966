import { Layout } from 'antd'

import HeaderDesktop from '@/components/header/HeaderDesktop'
import Footer from '@/components/footer'
import AppContainer from '@/components/containers/AppContainer'

import type { MainLayoutProps } from '@/layouts'

import './index.scss'

function MainLayoutDesktop({
  children,
  withoutContainer,
  withoutHeader,
  withoutFooter,
  landingPage,
  headerStyle,
  wrapperClassNameLayout,
}: MainLayoutProps) {
  return (
    <Layout>
      {!withoutHeader && (
        <HeaderDesktop landingPage={landingPage} style={headerStyle} />
      )}
      <Layout
        hasSider
        className={`main-layout-desktop ${wrapperClassNameLayout}`}
      >
        {!withoutContainer ? (
          <Layout.Content className="main-layout-desktop--content">
            <AppContainer>{children}</AppContainer>
            {!withoutFooter && <Footer />}
          </Layout.Content>
        ) : (
          <Layout.Content className="main-layout-desktop--content">
            <div className="main-layout-desktop--content--inner">
              {children}
            </div>
            {!withoutFooter && <Footer />}
          </Layout.Content>
        )}
      </Layout>
    </Layout>
  )
}

export default MainLayoutDesktop

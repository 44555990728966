import { Fragment } from 'react'
import { useWindowSize } from 'react-use'

import { Col, Row, Space, Typography } from 'antd'

import EPPoint from '../profile/EPPoint'
import DailyCheckin from '../daily-checkin'
import Clipboard from '../systems/Clipboard'
import ChainLogo from '../systems/chain/ChainLogo'

import { useUserStore } from '@/stores/user.store'
import { useMainStore } from '@/stores/main.store'

import { ellipsisText, shortenAddress2Direction } from '@/helpers'

function BasicInfo() {
  const userProfile = useUserStore((state) => state.userProfile)

  let username: string | undefined
  let _id = ''

  if (userProfile) {
    username = userProfile.username
    _id = userProfile._id
  }

  const { width } = useWindowSize()
  const {
    wallet: { address, chain },
  } = useMainStore((state) => state)

  if (!userProfile) return <Fragment />

  return (
    <Row gutter={[4, 8]} justify="center">
      <Col span={24}>
        <Space size={8} className="user-name">
          <Typography.Title level={4} style={{ fontWeight: 300 }}>
            {ellipsisText(username || _id, 13)}
          </Typography.Title>
          <Clipboard content={`${username || _id}`} />
        </Space>
      </Col>
      {width <= 1300 && (
        <Col span={24}>
          <Space style={{ width: '100%' }}>
            {width <= 1200 && !!address && (
              <Row gutter={[8, 8]}>
                <Col>
                  <ChainLogo chainId={chain} />
                </Col>

                <Col>
                  <Typography.Text>
                    {shortenAddress2Direction(address, 6, 4)}
                  </Typography.Text>
                </Col>
              </Row>
            )}
            {width <= 1300 && <EPPoint maxWidthText={60} />}
          </Space>
          {width <= 1100 && (
            <Space>
              <DailyCheckin style={{ padding: 0 }} />
            </Space>
          )}
        </Col>
      )}
    </Row>
  )
}

export default BasicInfo

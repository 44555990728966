import React, { useEffect, useState, CSSProperties, Fragment } from 'react'
import { useWindowSize } from 'react-use'

import { Col, Row, Space, Button } from 'antd'

// components
import Brand from '@/components/systems/brand'
import MenuNav from '@/components/header/MenuNav'
import DailyCheckin from '@/components/daily-checkin'
import Wallet from '@/components/wallet'
import UserNavDesktop from '@/components/user-nav/UserNavDesktop'
import LogIn from '../login'
import EPPoint from '../profile/EPPoint'
import CsrProvider from '@/providers/csr.provider'

import { useIsLoggedIn } from '@/hooks/useIsLoggedIn'

// styles
import './index.scss'

type HeaderDesktopType = {
  style?: CSSProperties
  landingPage?: boolean
}

function HeaderDesktop({ style, landingPage = false }: HeaderDesktopType) {
  const isLogin = useIsLoggedIn()
  const [component, setComponent] = useState<React.ReactNode>()
  // TODO: re-handle later
  const { width } = useWindowSize()

  useEffect(() => {
    if (isLogin)
      return setComponent(
        <Col>
          <Row gutter={12} wrap={false}>
            {width > 1230 && (
              <Col>
                <DailyCheckin />
              </Col>
            )}
            {width > 1380 && (
              <Col>
                <Wallet />
              </Col>
            )}
            {width > 1550 && (
              <Col>
                <Button className="wallet--main-btn" type="text">
                  <EPPoint maxWidthText={60} />
                </Button>
              </Col>
            )}
            <Col flex={1}>
              <UserNavDesktop />
            </Col>
          </Row>
        </Col>,
      )
    setComponent(
      <Col>
        <LogIn />
      </Col>,
    )
  }, [isLogin, width])

  return (
    <div className="header header-desktop--container" style={style}>
      <Row
        gutter={24}
        align="middle"
        justify={`${landingPage ? 'center' : 'space-between'}`}
        className="header-desktop--inner"
        wrap={false}
      >
        <Col>
          <Space size={12}>
            <Brand style={{ width: 162 }} />
          </Space>
        </Col>
        {landingPage ? null : (
          <Fragment>
            <Col className="w-full">
              <CsrProvider>
                <MenuNav />
              </CsrProvider>
            </Col>
            {component}
          </Fragment>
        )}
      </Row>
    </div>
  )
}

export default HeaderDesktop

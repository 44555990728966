import { useEffect, useState } from 'react'
// antd
import { Col, Dropdown, MenuProps, Row } from 'antd'

import BasicInfo from './BasicInfo'
import Logout from '@/components/systems/Logout'
import ProfileButton from './ProfileButton'
import Disconnect from '../systems/Disconnect'
import UserAvatar from '../user-avatar/UserAvatar'

import { useMainStore } from '@/stores/main.store'
// styles
import './index.scss'

const ITEMS: MenuProps['items'] = [
  {
    label: (
      <Row justify="center">
        <Col>
          <UserAvatar />
        </Col>
      </Row>
    ),
    key: '-1',
  },
  {
    label: <BasicInfo />,
    key: '0',
  },
  {
    type: 'divider',
  },
  {
    label: <ProfileButton block style={{ textAlign: 'left' }} />,
    key: '1',
  },
  {
    label: (
      <Logout block style={{ padding: 0, height: 32, background: 'none' }} />
    ),
    key: '3',
  },
]

const LOGON_ITEMS: MenuProps['items'] = [
  {
    label: (
      <Row justify="center">
        <Col>
          <UserAvatar />
        </Col>
      </Row>
    ),
    key: '-1',
  },
  {
    label: <BasicInfo />,
    key: '0',
  },
  {
    type: 'divider',
  },
  {
    label: <ProfileButton block style={{ textAlign: 'left' }} />,
    key: '1',
  },
  {
    label: (
      <Disconnect
        block
        style={{ padding: 0, height: 32, background: 'none' }}
      />
    ),
    key: '2',
  },
  {
    label: (
      <Logout block style={{ padding: 0, height: 32, background: 'none' }} />
    ),
    key: '3',
  },
]

function UserNavDesktop() {
  const [items, setItems] = useState(ITEMS)
  const connectingAddress = useMainStore((state) => state.wallet.address)

  useEffect(() => {
    if (!connectingAddress) return setItems(ITEMS)
    setItems(LOGON_ITEMS)
  }, [connectingAddress])

  return (
    <Dropdown
      menu={{ items }}
      placement="bottomRight"
      overlayClassName="user-nav--dropdown-content"
    >
      <UserAvatar size={35} className="user-nav--btn-dropdown" />
    </Dropdown>
  )
}

export default UserNavDesktop

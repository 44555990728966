import { Fragment, useMemo } from 'react'

import { Button, Typography } from 'antd'
import ChainLogo from '../systems/chain/ChainLogo'

import { useMainStore } from '@/stores/main.store'

import { shortenAddress2Direction } from '@/helpers'

import { TextProps } from 'antd/lib/typography/Text'

// styles
import './index.scss'

type WalletProps = {
  copyable?: boolean
}

function Wallet({ copyable = false }: WalletProps) {
  const { wallet } = useMainStore((state) => state)

  const copyProps: TextProps['copyable'] = useMemo(() => {
    if (!copyable) return false

    return {
      text: wallet.address,
    }
  }, [wallet, copyable])

  if (!wallet.address) return <Fragment />

  return (
    <Button
      type="text"
      icon={<ChainLogo chainId={wallet.chain} />}
      className="wallet--main-btn"
    >
      <Typography.Text type="success" copyable={copyProps}>
        {shortenAddress2Direction(wallet.address, 6, 4)}
      </Typography.Text>
    </Button>
  )
}

export default Wallet
